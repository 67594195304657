import { filter, firstValueFrom, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Battery } from './_interfaces/battery';
import { CityDetails } from './_interfaces/city-details';
import { GoogleMerchantsProduct } from './_interfaces/google-merchants-product';
import { SearchableVehicle } from './_interfaces/searchable-vehicle';
import { DataLayerService } from './_services/data-layer.service';
import { GoogleMerchantsService } from './_services/google-merchants.service';
import { InterfaceService } from './_services/interface.service';
import { RequestService } from './_services/request.service';
import { SessionService } from './_services/session.service';
import { StoreService } from './_services/store.service';
import { SupportScriptsService } from './_services/support-scripts.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  /* ------------------------------------------------------------------------ */
  /* Host Listeners --------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    const target = event.target as HTMLElement;

    if (
      target.classList.contains('cc-allow') ||
      target.classList.contains('cc-dismiss')
    ) {
      this.interfaceService.updateWhatsAppCTAIsAbove(false);
    }
  }

  /* ------------------------------------------------------------------------ */
  /* Private ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Subscriptions ---------------------------------------------------------- */
  private flagsSubscriptions: Subscription;
  private subscriptions: Subscription;

  /* Strings ---------------------------------------------------------------- */
  private leadSource$: string;
  private utmCampaign$: string;
  private utmMedium$: string;
  private utmSource$: string;

  /* ------------------------------------------------------------------------ */
  /* Public ----------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Objects ---------------------------------------------------------------- */
  public googleMerchantsProduct$: GoogleMerchantsProduct;
  public selectedBattery$: Battery;
  public selectedCity$: CityDetails;
  public selectedVehicle$: SearchableVehicle;

  /* Booleans --------------------------------------------------------------- */
  public hideCheckPricesOnScroll$: boolean;
  public menuIsOpen$: boolean;
  public renderCheckPrices$: boolean;
  public showDialogBatteryUnavailable$: boolean;
  public showDialogCityInactive$: boolean;
  public showDialogCityUnavailable$: boolean;
  public showDialogComplexExchange$: boolean;
  public showDialogServiceUnavailable$: boolean;
  public showSpinning$: boolean;
  public userFromHeavyVehicle$: boolean;
  public whatsAppCTAIsAbove$: boolean;

  /* Strings ---------------------------------------------------------------- */
  public pageTitle$: string;
  public phoneNumber$: string;
  public whatsAppWelcome$: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private dataLayerService: DataLayerService,
    private googleMerchantsService: GoogleMerchantsService,
    private interfaceService: InterfaceService,
    private location: Location,
    private ngZone: NgZone,
    private renderer2: Renderer2,
    private requestService: RequestService,
    private router: Router,
    private sessionService: SessionService,
    private storeService: StoreService,
    private supportScriptsService: SupportScriptsService
  ) {
    this.flagsSubscriptions = new Subscription();
    this.subscriptions = new Subscription();

    this.leadSource$ = '';
    this.utmCampaign$ = '';
    this.utmMedium$ = '';
    this.utmSource$ = '';

    this.googleMerchantsProduct$ = {} as GoogleMerchantsProduct;
    this.selectedBattery$ = {} as Battery;
    this.selectedCity$ = {} as CityDetails;
    this.selectedVehicle$ = {} as SearchableVehicle;

    this.hideCheckPricesOnScroll$ = false;
    this.menuIsOpen$ = false;
    this.renderCheckPrices$ = false;
    this.showDialogBatteryUnavailable$ = false;
    this.showDialogCityInactive$ = false;
    this.showDialogCityUnavailable$ = false;
    this.showDialogComplexExchange$ = false;
    this.showDialogServiceUnavailable$ = false;
    this.showSpinning$ = false;
    this.userFromHeavyVehicle$ = false;
    this.whatsAppCTAIsAbove$ = false;

    this.pageTitle$ = '';
    this.phoneNumber$ = '';
    this.whatsAppWelcome$ = '';
  }

  ngOnInit(): void {
    this.supportScriptsService.injectScripts(this.renderer2, this.document);

    this.subscriptions.add(
      this.storeService.leadSource$.subscribe(
        (value) => (this.leadSource$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.utmCampaign$.subscribe(
        (value) => (this.utmCampaign$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.utmMedium$.subscribe(
        (value) => (this.utmMedium$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.utmSource$.subscribe(
        (value) => (this.utmSource$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.googleMerchantsProduct$.subscribe(
        (value) => (this.googleMerchantsProduct$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.selectedBattery$.subscribe(
        (value) => (this.selectedBattery$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.selectedCity$.subscribe(
        (value) => (this.selectedCity$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.selectedVehicle$.subscribe(
        (value) => (this.selectedVehicle$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.hideCheckPricesOnScroll$.subscribe(
        (value) => (this.hideCheckPricesOnScroll$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.menuIsOpen$.subscribe(
        (value) => (this.menuIsOpen$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.renderCheckPrices$.subscribe(
        (value) => (this.renderCheckPrices$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.showDialogBatteryUnavailable$.subscribe(
        (value) => (this.showDialogBatteryUnavailable$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.showDialogCityInactive$.subscribe(
        (value) => (this.showDialogCityInactive$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.showDialogCityUnavailable$.subscribe(
        (value) => (this.showDialogCityUnavailable$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.showDialogComplexExchange$.subscribe(
        (value) => (this.showDialogComplexExchange$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.showDialogServiceUnavailable$.subscribe(
        (value) => (this.showDialogServiceUnavailable$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.showSpinning$.subscribe(
        (value) => (this.showSpinning$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.userFromHeavyVehicle$.subscribe(
        (value) => (this.userFromHeavyVehicle$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.whatsAppCTAIsAbove$.subscribe(
        (value) => (this.whatsAppCTAIsAbove$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.pageTitle$.subscribe(
        (value) => (this.pageTitle$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.phoneNumber$.subscribe(
        (value) => (this.phoneNumber$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.whatsAppWelcome$.subscribe(
        (value) => (this.whatsAppWelcome$ = value)
      )
    );

    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((event) => {
            return event instanceof NavigationEnd;
          })
        )
        .subscribe((event: any) => {
          const floatingBlackList = ['/passos'];

          if (event.url === '/') {
            this.interfaceService.updateHideCheckPricesOnScroll(true);
          } else {
            this.interfaceService.updateHideCheckPricesOnScroll(false);
          }

          if (
            event.url.includes('/entrega/') ||
            floatingBlackList.includes(event.url)
          ) {
            this.interfaceService.updateRenderCheckPrices(false);
          } else {
            this.interfaceService.updateRenderCheckPrices(true);
          }
        })
    );

    if (isPlatformBrowser(this.platformId)) {
      this.saveURLParams();

      this.saveTheDayOfTheFirstVisit();

      this.extractAndManipulateURLParams();
    }

    this.flagsSubscriptions = this.interfaceService.combinedFlags$.subscribe(
      ([
        liftResume$,
        menuIsOpen$,
        showBottomSheetTimeline$,
        showDialogBatteryUnavailable$,
        showDialogBlackFriday$,
        showDialogCityInactive$,
        showDialogCityUnavailable$,
        showDialogComplexExchange$,
        showDialogServiceUnavailable$,
        showDialogBatteryUseless$,
        showDialogDifferentCities$,
        showDialogTerms$,
        showDialogCancelOrder$,
        showDialogEditOrder$,
        showDialogUnusableBattery$,
        showSelectCityModal$,
        showSelectVehicleModal$,
      ]) => {
        const oneOfTheFlagsAreTrue =
          liftResume$ ||
          menuIsOpen$ ||
          showBottomSheetTimeline$ ||
          showDialogBatteryUnavailable$ ||
          showDialogBlackFriday$ ||
          showDialogCityInactive$ ||
          showDialogCityUnavailable$ ||
          showDialogComplexExchange$ ||
          showDialogServiceUnavailable$ ||
          showDialogBatteryUseless$ ||
          showDialogDifferentCities$ ||
          showDialogTerms$ ||
          showDialogCancelOrder$ ||
          showDialogEditOrder$ ||
          showDialogUnusableBattery$ ||
          showSelectCityModal$ ||
          showSelectVehicleModal$;

        const body = this.renderer2.selectRootElement('body', true);
        const floating = this.renderer2.selectRootElement('.floating', true);
        const toolbar = this.renderer2.selectRootElement('.toolbar', true);
        const userAgent = window.navigator.userAgent;

        if (oneOfTheFlagsAreTrue) {
          this.renderer2.addClass(body, 'overflow-hidden');

          if (userAgent.includes('Windows')) {
            this.renderer2.setStyle(body, 'padding-right', '17px');
            this.renderer2.setStyle(floating, 'width', 'calc(100% - 17px)');
            this.renderer2.setStyle(toolbar, 'padding-right', '17px');
          }
        } else {
          this.renderer2.removeClass(body, 'overflow-hidden');

          if (userAgent.includes('Windows')) {
            this.renderer2.removeStyle(body, 'padding-right');
            this.renderer2.removeStyle(floating, 'width');
            this.renderer2.removeStyle(toolbar, 'padding-right');
          }
        }
      }
    );
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const body = document.querySelector('body');

      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            const addedNode = mutation.addedNodes[0] as HTMLElement;

            if (
              addedNode.classList.contains('cc-grower') ||
              addedNode.classList.contains('cc-window')
            ) {
              this.ngZone.run(() => {
                this.interfaceService.updateWhatsAppCTAIsAbove(true);
              });

              observer.disconnect();
            }
          }
        }
      });

      observer.observe(body, {
        childList: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private extractAndManipulateURLParams(): void {
    const params = this.getURLSearchParams();

    if (params.toString().length === 0) {
      if (
        ['googleadspesadas', 'googlepesadas', 'oferta', 'recompre'].includes(
          this.leadSource$
        )
      ) {
        return;
      }

      const referrer =
        sessionStorage.getItem('referrer') || this.document.referrer;

      const leadSource = referrer ? 'organico' : 'direto';

      this.storeService.updateLeadSource(leadSource);

      sessionStorage.setItem('referrer', referrer);

      if (leadSource === 'direto') {
        this.storeService.updatePhoneNumber('4020.9721');
        this.storeService.updateWhatsAppWelcome(
          'Olá, preciso de uma bateria para o meu veículo!'
        );
      }

      const session = {
        leadsourceNonDirect: leadSource,
        originNonDirect: this.utmCampaign$,
        timestamp: Date.now(),
        urlParams: '',
        utmMediumNonDirect: this.utmMedium$,
        utmSourceNonDirect: this.utmSource$,
      };

      this.sessionService.addSession(session);

      return;
    }

    const googleMerchantsID = params.get('gmid');
    const googleShopping = params.get('googleshopping');
    const regionId = params.get('regionId') || params.get('region_id');
    const utmBlogWidgetPostSlug = params.get('utm_blog_widget_post_slug');
    const utmCampaign = params.get('utm_campaign')?.toLocaleLowerCase();
    const utmLeadSource = params.get('utm_leadsource')?.toLocaleLowerCase();
    const utmMedium = params.get('utm_medium')?.toLocaleLowerCase();
    const utmSource = params.get('utm_source')?.toLocaleLowerCase();
    const utmTesteAB = params.get('utm_testeab');
    const utmTipoSearch = params.get('utm_tiposearch');

    if (googleMerchantsID && isPlatformBrowser(this.platformId)) {
      this.getGoogleMerchantsProduct(
        environment.apis.mouraSearch,
        environment.keys.mouraSearch,
        'v1',
        googleMerchantsID,
        regionId
      );
    }

    if (googleMerchantsID || googleShopping) {
      this.sendUserFrom('Google Shopping');
    }

    if (googleShopping) {
      this.storeService.updateLeadSource('googlemerchantads');
      this.storeService.updateLeadSourceDetails('googlemerchant_formulario');
      this.storeService.updateUserFromGoogleShopping(true);
      this.storeService.updateUserFromPartnership(true);
      this.storeService.updateUserOrigin('Google Merchants');

      this.dataLayerService.push({
        event: 'Request delivery (Google Shopping)',
      });
    }

    if (googleShopping && !this.userFromHeavyVehicle$) {
      this.storeService.updatePhoneNumber('4020.1784');
    }

    if (utmBlogWidgetPostSlug) {
      this.storeService.updateUTMBlogWidgetPostSlug(utmBlogWidgetPostSlug);
    }

    if (utmCampaign) {
      this.storeService.updateUTMCampaign(utmCampaign);
    }

    if (
      utmCampaign &&
      ['full_banner_home', 'portalbanner'].includes(utmCampaign)
    ) {
      this.storeService.updateLeadSource('portalbanner');
      this.storeService.updateLeadSourceDetails('portalbanner_formulario');
      this.storeService.updatePhoneNumber('4003.9129');
      this.storeService.updateUserOrigin('Portal Banner');

      this.dataLayerService.push({
        event: 'Request delivery from Portal Moura (Banner)',
      });
    }

    if (
      utmCampaign &&
      ['portalwidget', 'widget-moura-facil'].includes(utmCampaign)
    ) {
      this.storeService.updateLeadSource('portalwidget');
      this.storeService.updateLeadSourceDetails('portalwidget_formulario');
      this.storeService.updatePhoneNumber('3003.0861');
      this.storeService.updateUserOrigin('Portal Widget');

      this.dataLayerService.push({
        event: 'Request delivery from Portal Moura (Widget)',
      });
    }

    if (utmLeadSource) {
      this.storeService.updateLeadSource(utmLeadSource);
    }

    if (utmLeadSource && utmLeadSource.includes('branding')) {
      this.storeService.updateLeadSource('branding');
      this.storeService.updatePhoneNumber('4003.9458');

      this.dataLayerService.push({
        event: 'Request delivery (branding)',
      });
    }

    const isGMB = utmLeadSource === 'gmb';

    if (utmLeadSource && isGMB) {
      this.storeService.updateLeadSource('googlemeunegocio');
      this.storeService.updatePhoneNumber('4003.9105');

      this.dataLayerService.push({
        event: 'Request delivery (Google My Business)',
      });
    }

    if (utmMedium) {
      this.storeService.updateUTMMedium(utmMedium);
    }

    if (utmSource) {
      this.storeService.updateUTMSource(utmSource);
    }

    if (
      utmSource &&
      ['blog', 'blog_widget', 'blogwidget'].includes(utmSource)
    ) {
      this.storeService.updateLeadSource('blogwidget');
      this.storeService.updatePhoneNumber('4020.3602');

      this.dataLayerService.push({
        event: 'Request delivery (blogwidget)',
      });
    }

    if (utmSource === 'organico') {
      this.storeService.updateLeadSource('organico');
      this.storeService.updatePhoneNumber('4020.5278');

      this.dataLayerService.push({
        event: 'Request delivery (organico)',
      });
    }

    if (
      utmSource === 'bing' &&
      utmMedium === 'cpc' &&
      utmCampaign.includes('BING_SCH'.toLocaleLowerCase())
    ) {
      this.storeService.updateLeadSource('bingads');
      this.storeService.updatePhoneNumber('4020.5253');
      this.storeService.updateWhatsAppWelcome(
        encodeURI(`Olá, preciso de uma bateria para o meu carro!`)
      );

      this.dataLayerService.push({
        event: 'Request delivery (bingads)',
      });
    }

    if (
      utmSource === 'bing' &&
      utmMedium === 'cpc' &&
      utmCampaign.includes('Bing_Shopping'.toLocaleLowerCase())
    ) {
      this.storeService.updateLeadSource('bingmerchantads');
      this.storeService.updatePhoneNumber('3003.0492');
      this.storeService.updateUTMCampaign('bingmerchantads');

      this.dataLayerService.push({
        event: 'Request delivery (bingmerchantads)',
      });
    }

    const isOnHeavyVehicles =
      this.requestService.getHostname().split('.')[0] === 'pesadas' ||
      this.location.path(true).includes('baterias-linha-pesadas');

    if (
      utmSource === 'google' &&
      !googleShopping &&
      !isGMB &&
      !isOnHeavyVehicles
    ) {
      this.storeService.updateLeadSource('googleadsleves');
      this.storeService.updateLeadSourceDetails('adwords_formulario');
      this.storeService.updatePhoneNumber('4020.3843');
      this.storeService.updateWhatsAppWelcome(
        encodeURIComponent(
          '[Canal Exclusivo de Vendas | Delivery Moura Fácil - Protocolo #720249] Olá, preciso comprar uma bateria para o meu carro. Poderia me ajudar?'
        )
      );

      this.dataLayerService.push({
        event: 'Request delivery (googleadsleves)',
      });

      // this.insertMouseflow();
    }

    if (utmSource === 'moura-portal') {
      this.storeService.updateLeadSource('portal');

      this.dataLayerService.push({
        event: 'Request delivery (moura-portal)',
      });
    }

    if (utmSource === 'googlemerchantorganico') {
      this.storeService.updateLeadSource('googlemerchantorganico');
      this.storeService.updatePhoneNumber('4003.1636');

      this.dataLayerService.push({
        event: 'Request delivery (googlemerchantorganico)',
      });
    }

    if (
      utmCampaign?.includes('displaymeta') ||
      utmSource?.includes('displaymeta')
    ) {
      this.storeService.updateLeadSource('displaymeta');
      this.storeService.updatePhoneNumber('3003.0891');

      this.dataLayerService.push({
        event: 'Request delivery (display meta)',
      });
    }

    if (utmTesteAB) {
      this.storeService.updateUTMTesteAB(utmTesteAB);
    }

    if (utmTipoSearch) {
      this.storeService.updateUTMTipoSearch(utmTipoSearch);
    }

    // As campanhas devem seguir o padrão de letras minusculas, caso contrário não irá identificar

    // Este bloco vem por último porque precisa ter precedência sobre todas as
    // checagens anteriores. Ou seja, mesmo que as checagens anteriores
    // retornem/sejam verdadeiras, estas precisam sobrescrever todas elas
    if (utmCampaign && utmCampaign.includes('demand-gen')) {
      this.storeService.updateLeadSource('demandgenads');
      this.storeService.updateLeadSourceDetails(
        'demand_gen_ads_clique_telefônico'
      );
      this.storeService.updatePhoneNumber('4020.1698');
      this.storeService.updateUserOrigin('AdsDemandGen');

      this.dataLayerService.push({
        event: 'Request delivery (Demand Gen)',
      });
    }

    if (utmCampaign && utmCampaign.includes('discovery')) {
      this.storeService.updateLeadSource('discoveryads');
      this.storeService.updateLeadSourceDetails(
        'ads_discovery_clique_telefônico'
      );
      this.storeService.updatePhoneNumber('4003.6082');
      this.storeService.updateUserOrigin('Ads Discovery');
      this.storeService.updateUTMCampaign('adsdiscovery');

      this.dataLayerService.push({
        event: 'Request delivery (Ads Discovery)',
      });
    }

    if (
      utmCampaign &&
      utmCampaign.includes('display') &&
      !utmCampaign.includes('meta') &&
      !utmCampaign.includes('rmkt') &&
      !utmCampaign.includes('remarketing')
    ) {
      this.storeService.updateLeadSource('displayads');
      this.storeService.updateLeadSourceDetails(
        'ads_display_clique_telefônico'
      );
      this.storeService.updatePhoneNumber('4020.6174');
      this.storeService.updateUserOrigin('AdsDisplay');
      this.storeService.updateUTMCampaign('adsdisplay');

      this.dataLayerService.push({
        event: 'Request delivery (Ads Display)',
      });
    }

    if (
      utmCampaign &&
      (utmCampaign.includes('display_rmkt') ||
        (utmCampaign.includes('display') &&
          utmCampaign.includes('remarketing')))
    ) {
      this.storeService.updateLeadSource('displayrmktads');
      this.storeService.updateLeadSourceDetails(
        'ads_rmkt_display_clique_telefônico'
      );
      this.storeService.updatePhoneNumber('4020.8325');
      this.storeService.updateUserOrigin('AdsRmktDisplay');
      this.storeService.updateUTMCampaign('adsrmktdisplay');

      this.dataLayerService.push({
        event: 'Request delivery (Ads Rmkt Display)',
      });
    }

    if (utmCampaign && utmCampaign.includes('facebook')) {
      this.storeService.updateLeadSource('adsfacebook');
      this.storeService.updateLeadSourceDetails(
        'ads_facebook_clique_telefônico'
      );
      this.storeService.updatePhoneNumber('3003.0453');
      this.storeService.updateUserOrigin('AdsFacebook');
      this.storeService.updateUTMCampaign('adsfacebook');
      this.storeService.updateWhatsAppWelcome(
        encodeURIComponent(
          '[Canal Exclusivo de Vendas | Delivery Moura Fácil - Protocolo #320245] Olá, preciso comprar uma bateria para o meu carro. Poderia me ajudar?'
        )
      );

      this.dataLayerService.push({
        event: 'Request delivery (Ads Facebook)',
      });
    }

    if (utmCampaign && utmCampaign.includes('pmax')) {
      this.storeService.updateLeadSource('pmax');
      this.storeService.updateLeadSourceDetails('ads_pmax_clique_telefônico');
      this.storeService.updatePhoneNumber('4003.9539');
      this.storeService.updateUserOrigin('AdsPmax');

      this.dataLayerService.push({
        event: 'Request delivery (Ads Pmax)',
      });
    }

    // A campanha da microsoft_pmax precisa ser depois da pmax,
    // pois ambas compartilham da mesma palavra (pmax) e se permitir que
    // a microsoft_pmax seja a primeira, iria cair na pmax e não iria mudar.

    if (utmCampaign && utmCampaign.includes('microsoft_pmax')) {
      this.storeService.updateLeadSource('pmaxmicrosoft');
      this.storeService.updateLeadSourceDetails(
        'ads_pmax_microsoft_clique_telefônico'
      );
      this.storeService.updatePhoneNumber('4003.3087');
      this.storeService.updateUserOrigin('AdsPmaxMicrosoft');
      this.storeService.updateUTMCampaign('adspmaxmicrosoft');

      this.dataLayerService.push({
        event: 'Request delivery (Ads Pmax Microsoft)',
      });
    }

    if (utmCampaign && utmCampaign.includes('rtbhouse')) {
      this.storeService.updateLeadSource('rtbhouse');
      this.storeService.updateLeadSourceDetails(
        'ads_rtbhouse_clique_telefônico'
      );
      this.storeService.updatePhoneNumber('4020.1790');
      this.storeService.updateWhatsAppWelcome(
        encodeURI('Preciso de uma bateria para o meu carro.')
      );

      this.storeService.updateUserOrigin('AdsRTBHouse');
      this.storeService.updateUTMCampaign('adsrtbhouse');

      this.dataLayerService.push({
        event: 'Request delivery (Ads RTB House)',
      });
    }

    if (utmCampaign && utmCampaign.includes('tiktok')) {
      this.storeService.updateLeadSource('adstiktok');
      this.storeService.updateLeadSourceDetails('ads_tiktok_clique_telefônico');
      this.storeService.updatePhoneNumber('3003.0460');
      this.storeService.updateUserOrigin('AdsTikTok');
      this.storeService.updateUTMCampaign('adstiktok');

      this.dataLayerService.push({
        event: 'Request delivery (Ads TikTok)',
      });
    }

    if (utmCampaign && utmCampaign.includes('youtube')) {
      this.storeService.updateLeadSource('youtubeads');
      this.storeService.updateLeadSourceDetails(
        'youtube_ads_clique_telefônico'
      );
      this.storeService.updatePhoneNumber('4020.1623');
      this.storeService.updateUserOrigin('AdsYoutube');

      this.dataLayerService.push({
        event: 'Request delivery (Ads Youtube)',
      });
    }

    const session = {
      leadsourceNonDirect: this.leadSource$,
      originNonDirect: this.utmCampaign$,
      timestamp: Date.now(),
      utmSourceNonDirect: this.utmSource$,
      utmMediumNonDirect: this.utmMedium$,
      urlParams: sessionStorage.getItem('urlParams'),
    };

    this.sessionService.addSession(session);
  }

  private getGoogleMerchantsProduct(
    apiURL: string,
    apiKey: string,
    apiVersion: string,
    id: string,
    regionId: string
  ): void {
    firstValueFrom(
      this.googleMerchantsService.getProductDetails(
        apiURL,
        apiKey,
        apiVersion,
        id,
        regionId
      )
    )
      .then((response) => {
        let model = null;

        response.product.customAttributes.forEach((objeto) => {
          if (objeto['name'] === 'model') {
            model = objeto['value'];

            return;
          }
        });

        this.storeService.updateWhatsAppWelcome(
          encodeURI(`Olá, preciso da bateria ${model} para o meu veículo.`)
        );

        return this.storeService.updateGoogleMerchantsProduct(response);
      })
      .catch((_) =>
        this.router.navigateByUrl('nao-encontrada', {
          skipLocationChange: true,
        })
      );
  }

  private getURLSearchParams(): URLSearchParams {
    const urlPath = window.sessionStorage
      ? sessionStorage.getItem('urlParams')
      : '';

    return new URLSearchParams(urlPath ? urlPath : '');
  }

  private insertMouseflow(): void {
    window._mfq = window._mfq || [];

    let script = this.renderer2.createElement('script');

    script.src =
      '//cdn.mouseflow.com/projects/7eca9b85-97db-4e23-b1ad-1eddc4bb066e.js';
    script.type = 'text/javascript';
    script.defer = true;

    this.renderer2.appendChild(this.document.head, script);
  }

  private removeBattery(): void {
    this.dataLayerService.push({
      event: 'Change battery',
      battery: this.selectedBattery$.model,
    });

    this.storeService.updateSelectedBattery({} as Battery);
  }

  private removeCity(): void {
    this.dataLayerService.push({
      event: 'Change city',
      city: this.selectedCity$.city,
    });

    this.storeService.updateSelectedCity({} as CityDetails);
  }

  private removeVehicle(): void {
    this.dataLayerService.push({
      event: 'Change vehicle',
      vehicle: this.selectedVehicle$.car_model,
    });

    this.storeService.updateSelectedVehicle({} as SearchableVehicle);
  }

  private saveTheDayOfTheFirstVisit(): void {
    const currentDate = new Date();
    const firstVisitDateStored = JSON.parse(
      localStorage.getItem('firstVisitDateStored')
    );

    let firstVisitDate: Date;

    if (firstVisitDateStored) {
      firstVisitDate = new Date(firstVisitDateStored);
    } else {
      firstVisitDate = new Date();

      localStorage.setItem(
        'firstVisitDateStored',
        JSON.stringify(firstVisitDate)
      );
    }

    const differenceInTime = currentDate.getTime() - firstVisitDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    localStorage.setItem('lastVisit', JSON.stringify(differenceInDays));
  }

  private saveURLParams(): void {
    const allowedPaths = ['/', '/conheca-mais', '/pedido'];

    const url = this.location.path();
    const path = url.split('?')[0] || '/';
    const params = url.split('?')[1] || '';

    const internalRedirect = this.document.referrer.includes(
      this.requestService.getHostname()
    );

    const isOrderWithReferrer =
      this.document.referrer.length > 0 && path === '/pedido';

    if (internalRedirect) {
      return;
    }

    if (allowedPaths.includes(path) || isOrderWithReferrer) {
      sessionStorage.setItem('urlParams', params);
    }
  }

  private sendUserFrom(value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.dataLayerService.push({
        event: `User from ${value}`,
      });
    }
  }

  public handleClickedCheckPrices(): void {
    this.interfaceService.updateShowSelectCityDropdown(false);
    this.interfaceService.updateShowSelectVehicleDropdown(false);

    if (!this.selectedCity$.id) {
      this.interfaceService.updateShowSelectCityModal(true);
    } else if (this.selectedCity$.id && !this.selectedVehicle$.id) {
      this.interfaceService.updateShowSelectVehicleModal(true);
    } else if (this.selectedBattery$.id) {
      this.interfaceService.updateShowSelectCityModal(false);

      this.interfaceService.updateShowSelectVehicleModal(false);

      this.sendEvent('order now', {
        button: 'steps - mobile',
      });

      this.router.navigate(['/passos']);
    }
  }

  public handleCloseDialogComplexExchange(): void {
    this.removeBattery();
    this.removeVehicle();

    this.interfaceService.updateShowDialogComplexExchange(false);
  }

  public handleCloseDialogServiceUnavailable(): void {
    this.removeBattery();
    this.removeCity();
    this.removeVehicle();

    this.interfaceService.updateShowDialogServiceUnavailable(false);

    window.location.reload();
  }

  public handleMenuToggled(event: boolean): void {
    this.interfaceService.updateMenuIsOpen(event);
  }

  public handleSearchRetailer(): void {
    this.removeBattery();
    this.removeCity();
    this.removeVehicle();

    this.interfaceService.updateShowDialogBatteryUnavailable(false);
    this.interfaceService.updateShowDialogCityInactive(false);
    this.interfaceService.updateShowDialogCityUnavailable(false);
    this.interfaceService.updateShowDialogComplexExchange(false);

    window.open('https://moura.com.br/revendas', '_blank');
  }

  public handleSelectAnotherCity(): void {
    this.removeBattery();
    this.removeCity();
    this.removeVehicle();

    this.interfaceService.updateShowDialogBatteryUnavailable(false);
    this.interfaceService.updateShowDialogCityInactive(false);
    this.interfaceService.updateShowDialogCityUnavailable(false);
  }

  public handleTalkViaWhatsApp(): void {
    this.removeBattery();
    this.removeCity();
    this.removeVehicle();

    this.interfaceService.updateShowDialogServiceUnavailable(false);

    window.open(
      `https://api.whatsapp.com/send?phone=551130905555&text=${this.whatsAppWelcome$}`,
      '_blank'
    );

    this.sendEvent('Talk with Us via WhatsApp (service unavailable)');
  }

  public scrollToTop(): void {
    const element = document.getElementsByTagName('body')[0];

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  public sendEvent(event: string, payload?: any): void {
    this.dataLayerService.push({
      event,
      ...payload,
    });
  }
}
